* {
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}

img {
   width: 100%;
}

.container {
   max-width: 1500px;
   margin: 0 auto;
}
.App {
   overflow: hidden;
}

@font-face {
   font-family: 'global-font';
   src: url(../src/fonts/ArmenianDecorativeU-Italic.ttf);
}

@font-face {
   font-family: 'poppins-font';
   src: url(../src/fonts/Poppins-ExtraBold.ttf);
}

:root {
   --cream-color: #c6900a;
}
